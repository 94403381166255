<template>
  <div id="landing-page">
    <div class="background-box">
        <img src="../../assets/images/om-background.png" alt="Webmagnat Logo">
    </div>
    <div class="app-landing-message">
      <h4>  </h4>
    </div>
    <div class="go-to-login">
    <div class="logo-box">
        <img src="../../assets/images/logo-landing.svg" alt="Webmagnat Logo">
    </div>
      <router-link to="/login">
        <button class="app-button landing-button">
          {{ $t('placeholder.continue') }}
          <i class="fas fa-arrow-right"></i>
      </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  created () {
    // this.$store.dispatch('hideLoading')

  }
}
</script>

<style lang="css" scoped>
</style>
